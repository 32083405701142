.signup-home-page-main{
    background: url(../../../../../public/images/banner.jpg);
    background-repeat: no-repeat;
    object-fit: cover;
    height:95vh;
    background-size: cover;
    background-position: center;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    
}
.row-main-signup{
    display: flex;
    justify-content: center;
    align-items: center;

}
.signup-in-form-main{
    display: flex;
    justify-content: center;
    flex-direction: column;

    top: 30vh;

    width:350px;

    padding: 25px 25px;

    border: none;


}
@media screen and (max-width:990px) {
    .signup-home-page-main{
        background: url(../../../../../public/images/banner.jpg);
        background-repeat: no-repeat;
        object-fit: cover;
        height:95vh;
        background-size: cover;
        background-position: center;
    
        display: flex;
        justify-content: center;
        align-items: end;

        padding-bottom: 100px;
        
    }
}
@media screen and (min-width:990px) {
    .row-main-signup{
        display: flex;
        justify-content: center;
        align-items: center;

        margin-right: 100px;

        margin-top: -80px;
    }
    .signup-in-form-main{
        display: flex;
        justify-content: center;
        flex-direction: column;
    
        top: 30vh;
    
        width:450px;
    
        padding: 25px 25px;
        
        border: none;
    
    }
}

.button-signup-main-button{
    border: none;
    border-radius: 8px;

    align-self: center;

    background-color: #122B4D;

    color: whitesmoke;

    height: 40px;
    width:200px;

    transition: all 0.2s ease-in-out;
}
.button-signup-main-button:hover{
    border:2px solid #122B4D;

    background-color: whitesmoke;

    color: #122B4D;
}
